import React, { useState, useEffect } from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Import i18n configuration

const images = [
  `${process.env.PUBLIC_URL}/image0A.png`,
  `${process.env.PUBLIC_URL}/image0B.png`,
  `${process.env.PUBLIC_URL}/image0C.png`,
  `${process.env.PUBLIC_URL}/image0D.png`,
  `${process.env.PUBLIC_URL}/image0E.png`,
];

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation(); // Translation hook
  const [language, setLanguage] = useState('en'); // Default language set to English

  // Function to get the correct index with wrap-around
  const getIndex = (index) => {
    return (index + images.length) % images.length;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => getIndex(prevIndex + 1));
    }, 3000); // Rotate every 3 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <div className="app-container">
      {/* Language Dropdown */}
      <div className="language-switcher">
        <select
          value={language}
          onChange={(e) => changeLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="ja">日本語</option>
          <option value="de">Deutsch</option>
        </select>
      </div>

      <div className="launch-container">
        <div className="text-container">
          <div className='welcomeTo'>{t('welcome')}</div>
          <img src={`${process.env.PUBLIC_URL}/app-icon.png`} alt="App Icon" className="app-icon2" />
        </div>

        {/* Start of Carousel */}
        <div className="carousel">
          {images.map((image, index) => {
            let position = '';
            if (index === currentIndex) {
              position = 'current';
            } else if (index === getIndex(currentIndex - 1)) {
              position = 'previous';
            } else if (index === getIndex(currentIndex + 1)) {
              position = 'next';
            } else if (index === getIndex(currentIndex - 2)) {
              position = 'far-previous';
            } else if (index === getIndex(currentIndex + 2)) {
              position = 'far-next';
            }

            return (
              <div key={index} className={`carousel-item ${position}`}>
                <img src={image} alt={`Slide ${index + 1}`} />
              </div>
            );
          })}
        </div>
        {/* End of Carousel */}
      </div>

      <footer>
        <p className="subtitle">{t('subtitle')}</p>
        <p className="subtitle2">
          <a href="https://www.neilsoft.com" target="_blank" rel="noopener noreferrer" className="no-blue-link">
            <span className="aSolutionBy">{t('solutionBy')}</span>
            <img src={`${process.env.PUBLIC_URL}/Neilsoft.png`} alt="Neilsoft Logo" className="neilsoft-logo-image" />
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
